import qs from 'qs';
import get from 'lodash/get';

const QueryParams = ({ children }) => {
	const queryStr = get(global, 'location.search', '').replace('?', '');
	const params = qs.parse(queryStr);
  return children({ params });
};

export default QueryParams;
