import React from 'react';
import PropTypes from 'prop-types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import get from 'lodash/get';

import {
  getMakerDetails,
  // handleJoinClick,
  makerGetRewardfulId,
  redirect,
} from '../utils';
import QueryParams from '../components/QueryParams';
// import Layout from '../components/Layout';
// import Header from '../components/Header';
// import Button from '../components/Button';
// import { IfClosed, IfOpen } from '../components/OpenFlags';
// import WaitlistFull from '../components/WaitlistFull';

// const MakerPlan = ({ maker }) => {

// https://www.themakersmob.com/membership/checkout?via=XYZ
// const refId = makerGetRewardfulId(maker.id);
// global.location.href = 'https://www.themakersmob.com/membership/checkout?via=' + refId;

// const makerFeatures = [
//   `${maker.name}'s Content`,
//   `${maker.name}'s Step by Step Tutorials`,
//   `${maker.name}'s Video Courses`,
//   `${maker.name}'s Project Plans`,
//   `Live Video Hangouts`,
//   `Private Member's Community Access`,
// ];

// return (
//   <div className="border border-grey-darkest md:border-r-0 p-6 md:w-1/3 rounded rounded-tr-none rounded-br-none my-4 text-left">
//     <div className="mb-8">
//       <h3 className="text-center text-2xl font-normal leading-none text-grey mb-1">
//         {maker.name} Only
//       </h3>
//       <div className="text-center text-md text-grey-dark">$19.95 / month</div>
//     </div>
//     <div className="text-lg text-grey mb-4 text-center">
//       Included in Your {maker.name} Membership
//     </div>
//     <ul className="list-reset text-grey-dark mx-2">
//       {makerFeatures.map((feat, idx) => (
//         <li className="mb-3 flex items-center" key={idx}>
//           <FontAwesomeIcon
//             icon="check-circle"
//             className="text-green mr-2 text-lg"
//           />
//           <div className="ml-1">{feat}</div>
//         </li>
//       ))}
//     </ul>
//     <Button
//       type="ghost"
//       className="w-full mt-4"
//       onClick={e => handleJoinClick(e, maker.id, false)}
//     >
//       Subscribe to {maker.name}
//     </Button>
//   </div>
// );
// };
// MakerPlan.propTypes = {
//   maker: PropTypes.shape({
//     id: PropTypes.string.isRequired,
//     name: PropTypes.string.isRequired,
//   }).isRequired,
// };

class PlansPage extends React.Component {
  componentDidMount() {
    const maker = getMakerDetails(this.props.queryParams.ref);
    const refId = makerGetRewardfulId(maker.id);
    if(!refId) {
      redirect('https://www.themakersmob.com/membership/checkout');
    } else {
      redirect('https://www.themakersmob.com/membership/checkout?via=' + refId);
    }
  }

  render() {
    return null;
  }
}

// const PlansPage = ({ queryParams }) => {
// const maker = getMakerDetails(queryParams.ref);
// const refId = makerGetRewardfulId(maker.id);

// if(global && global.location && global.location.href) {
//   redirect('https://www.themakersmob.com/membership/checkout?via=' + refId);
// }

// const renderThankYou =
//   get(global, 'location.search', '') === '?thankyou=true';

// const allAccessFeatures = [
//   "Samurai Carpenter's video tutorials",
//   "Jimmy DiResta's video tutorials",
//   "John Peter's video tutorials",
//   "Coming Soon - April Wilkerson's video tutorials",
//   '250+ Step by Step Video Tutorials',
//   '25+ Exclusive Video Courses',
//   'Exclusive Custom Project Plans',
//   'Project Materials & Cut Lists',
//   'Live Video Hangouts',
//   "Private Member's Community Access",
// ];

// return (
//   <Layout pageTitle="MakersMob Plans" type="dark">
//     <Header showJoinBtn={false} />
//     <div className="container mx-auto flex justify-center">
//       <IfClosed>
//         <div className="">
//           <div className="px-2 mb:px-0 mt-24 text-center">
//             <div className="mb-16 md:w-2/3 md:mx-auto">
//               <h1 className="text-white text-2xl md:text-4xl font-normal mb-3">
//                 Membership Closed
//               </h1>
//               <h2 className="text-lg md:text-xl text-grey-dark font-normal">
//                 The Makers Mob private community is currently closed to new
//                 members. Please join the waitlist to be notified of the next
//                 opening.
//               </h2>
//             </div>
//           </div>
//           <div className="">
//             <WaitlistFull
//               redirectUrl="https://www.joinmakersmob.com/plans?thankyou=true"
//               listId="awlist5239122"
//               subscribed={renderThankYou}
//               makersName=""
//             />
//           </div>
//         </div>
//       </IfClosed>
//       <IfOpen>
//         <div className="px-2 mb:px-0 mt-24 text-center">
//           <div className="mb-16 md:w-2/3 md:mx-auto">
//             <h1 className="text-white text-2xl md:text-4xl font-normal mb-3">
//               Start Your Journey Today
//             </h1>
//             <h2 className="text-lg md:text-xl text-grey-dark font-normal">
//               Select your favourite Maker or our most popular All Access plan
//               for unrestricted access to all our content.
//             </h2>
//           </div>

//           <div className="text-grey-darker mb-48 md:flex justify-center items-center">
//             {maker.name && maker.single && <MakerPlan maker={maker} />}

//             <div className="border border-grey-darkest p-6 md:w-1/2 rounded-lg shadow-lg text-left bg-grey-darkest text-white leading-normal relative overflow-hidden">
//               <div className="mb-8">
//                 <h3 className="text-center text-3xl font-normal leading-none mb-1">
//                   All Access
//                 </h3>
//                 <div className="text-center text-lg text-grey">
//                   $23.95 / month
//                 </div>
//               </div>
//               <div className="mb-6">
//                 <div className="text-lg text-white mb-4 text-center">
//                   Included in Your Membership
//                 </div>
//                 <ul className="list-reset text-grey mx-2">
//                   {allAccessFeatures.map((feat, idx) => (
//                     <li className="mb-3 flex items-center" key={idx}>
//                       <FontAwesomeIcon
//                         icon="check-circle"
//                         className="text-green mr-2 text-lg"
//                       />
//                       <div className="ml-1">{feat}</div>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//               <Button
//                 className="w-full mt-4 py-4 text-lg"
//                 onClick={e => handleJoinClick(e, queryParams.ref, true)}
//               >
//                 Subscribe to All Access
//               </Button>
//               <div
//                 className="absolute text-white bg-red p-1 uppercase tracking-wide text-center shadow font-bold"
//                 style={{
//                   width: 150,
//                   right: -40,
//                   top: 25,
//                   fontSize: '0.6em',
//                   transform: 'rotate(45deg)',
//                 }}
//               >
//                 Most Popular
//               </div>
//             </div>
//           </div>
//         </div>
//       </IfOpen>
//     </div>
//   </Layout>
// );
// };
PlansPage.propTypes = {
  queryParams: PropTypes.object,
};

const WithQueryParams = () => (
  <QueryParams>
    {({ params }) => <PlansPage queryParams={params} />}
  </QueryParams>
);

export { PlansPage };
export default WithQueryParams;
